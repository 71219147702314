.page-wrapper2 {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .styled-table {
    width: 97%;
    border-collapse: collapse;
    margin: 25px 0;
    margin-left: 20px;
    font-size: 0.9em;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: #f3f3f3;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
  
  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
    text-align: left;
  }
  
  .styled-table thead tr {
    background-color: #f78821;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
  }
  
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #d8771c;
  }


  
  