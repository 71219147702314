/* WhatsAppChatModal.css */
.whatsapp-modal {
  font-family: Arial, sans-serif;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background-color: #fafafa;
}

.message {
  padding: 8px 12px;
  border-radius: 20px;
  margin-bottom: 10px;
  max-width: 70%;
  word-wrap: break-word;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.sent {
  align-self: flex-end;
  background-color: #aec7e7;
}

.received {
  align-self: flex-start;
  background-color: #e4e6eb;
}

.message span {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.message div {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}

.input-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-top: 1px solid #f0f0f0;
  border-radius: 5px;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
}

.receiver-list {
  border-right: 1px solid #f0f0f0;
  max-height: 400px;
  overflow-y: auto;
}

.receiver-list .ant-list-item {
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.receiver-list .ant-list-item:hover {
  background-color: #f5f5f5;
}

.receiver-list .ant-list-item-active {
  background-color: #e6f7ff;
}

.custom-send-button {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #fff;
}

.custom-send-button:hover,
.custom-send-button:focus {
  background-color: #45a049;
  border-color: #45a049;
}

.custom-upload-button {
  background-color: #008cba;
  border-color: #008cba;
  color: #fff;
}

.custom-upload-button:hover,
.custom-upload-button:focus {
  background-color: #007bb5;
  border-color: #007bb5;
}
