/* src/components/Dashboard.css */
.card {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
    font-size: 16px;
    font-weight: bold;
}

.card-body {
    padding: 16px;
}
